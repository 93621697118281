<template>
  <div>
    <UserInfo />
    <Navigation />

    <div class="main-content pt-5">
      <div class="block">
        <div class="block">
          <h1 class="title">{{$t('views.notFound.oops')}}</h1>
        </div>
        <SomethingWentWrong :text="$t('views.notFound.text')"/>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfo from '@/components/shared/UserInfo.vue';
import Navigation from '@/components/shared/Navigation.vue';
import SomethingWentWrong from '@/components/shared/SomethingWentWrong.vue';

export default {
  name: 'NotFoundView',
  components: {
    UserInfo,
    Navigation,
    SomethingWentWrong
  }
}
</script>
